// videoData.js
const videoData = [
  /*
    {
      title: 'YouTube Video 1',
      description: 'This is a description of another church video.',
      date: 'October 10, 2024',
      src: 'https://www.youtube.com/embed/v0mNYhjUHGo',
      isYouTube: true,
    },
    */
  ];
  
  export default videoData;
  