// sermonVideoData.js
const sermonVideoData = [
  /*
    {
      title: 'Church Video 1',
      description: 'This is a description of the first church video.',
      date: 'October 10, 2024',
      src: '/videos/sample_video.mp4',
    },
    {
      title: 'YouTube Video 1',
      description: 'This is a description of another church video.',
      date: 'October 10, 2024',
      src: 'https://www.youtube.com/embed/another_video_id',
      isYouTube: true,
    },
    */
  ];
  
export default sermonVideoData;